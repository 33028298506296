import React, { useState, createContext } from 'react';
import Modal from "../components/shared/Modal"

export const ModalContext = createContext(null);

const ModalWrapper = ({ children }) => {
  const [content, setContent] = useState(null);

  const closeModal = () => setContent(null)

  return (
    <ModalContext.Provider value={{setContent}}>
      <Modal closeSelf={closeModal}>{content}</Modal>
      {children}
    </ModalContext.Provider>
  );
};


export default ModalWrapper;
