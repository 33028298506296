import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { NavLink} from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";


const defaultData = {
  email: '',
  name: '',
  surnames: '',
  phone: '',
  reason: '',
  text: '',
}

const Contact = () => {
  const { t, i18n } = useTranslation("footer", { keyPrefix: "form" });

  const [data, setData] = useState(defaultData)

  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState(false);

  const handleInputChange = (event) => {
    setAlert(false);
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = (event) => {
    // handleReCaptchaVerify();
    event.preventDefault();
    const {name, email, reason, text} = data

    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        'Nombre': name,
        'Correo': email,
        'Asunto': reason,
        'Mensaje': text,
        'locale': i18n.language,

        from_name: 'Landing On-road - Contact form',
        subject: reason,
        replyto: email,
        access_key: process.env.REACT_APP_W3FORMS_KEY
      })
    })
      .then(() => {
        setDisabled(true);
        // navigate('/form-sent')
        setAlert(true);
        setData(defaultData)
      })
  }

  
  return (
    <Form onSubmit={handleSubmit}>
      <h2 className="px-0 mb-4 text-uppercase fw-bold">
        {t("title")}
      </h2>

      <FormGroup>
        <Label for="name">{t("name")}</Label>
        <Input required type="text" name="name" value={data.name} onChange={handleInputChange}/>
      </FormGroup>
      <FormGroup>
        <Label for="email">{t("email")}</Label>
        <Input required type="email" name="email" value={data.email} onChange={handleInputChange}/>
      </FormGroup>
      <FormGroup>
        <Label for="clubName">{t("reason")}</Label>
        <Input required type="text" name="reason" value={data.reason} onChange={handleInputChange}/>
      </FormGroup>
      <FormGroup>
        <Label for="clubName">{t("consultation")}</Label>
        <Input required type="textarea" name="text" rows={4} value={data.text} onChange={handleInputChange}/>
      </FormGroup>

      <FormGroup check>
        <Input required type="checkbox"/>
        <Label>
          <Trans t={t} i18nKey="privacyCheckbox" components={[<NavLink to="/privacy" className="text-primary"/>]}/>
        </Label>
      </FormGroup>

      <Button disabled={disabled} type="submit" color="primary" className="w-100 mt-2 border-0">
        {t("submitBtn")}
      </Button>

      <Alert color="primary" isOpen={alert} className="mt-3">
        {t("sentAlert")}
      </Alert>

    </Form>
  )
}

export default Contact;