import React from 'react';
import { useTranslation } from "react-i18next";

import bgImg from "../../media/balearic-bg.svg"
import logo from "../../media/brand/logo_balearic.svg"

const Balearic = () => {
  const { t } = useTranslation("home", { keyPrefix: 'balearic' });
  return (
    <section
      className="text-center"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        gap: '2rem',
        padding: '7rem 3rem'
      }}
    >
      <h2 className="mainTitle fw-bolder text-white text-uppercase">
        {t("title")}
      </h2>
      <a href="https://www.balearicapartments-rentals.com/" alt="Balearic apartment rentals">
        <img src={logo} className="w-100 my-4" alt="" style={{maxWidth: 700}}/>
      </a>
    </section>
  )
}

export default Balearic;
