import React from "react";

import Navbar from "../components/shared/Navbar"
import MainSection from "../components/home/MainSection"
import Owner from "../components/home/Owner";
import FAQs from "../components/home/FAQs";
import Photos from "../components/home/Photos";
import Reviews from "../components/home/Reviews"
import Footer from "../components/shared/Footer"
import Balearic from "../components/home/Balearic";





const Home = () => {
  return (
    <div className="metropolis bg-white p-0 position-relative">
      <Navbar isHome />

      <MainSection />

      <Owner />

      <FAQs />

      <Photos />

      <Reviews />

      <Balearic />

      <Footer />
    </div>
  );
}

export default Home;