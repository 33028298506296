import React from 'react';
import { Col, Container, Row } from "reactstrap";
// import ShowOnVisible from "../shared/animations/ShowOnVisible";
import { useTranslation } from "react-i18next";

import jeremias from "../../media/reviewers/jeremias.webp"
import sylvia from "../../media/reviewers/sylvia.webp"
import daniel from "../../media/reviewers/daniel.webp"


const reviews = [
  { img: jeremias, name: 'Jeremias' },
  { img: sylvia, name: 'Sylvia' },
  { img: daniel, name: 'Daniel' }
]

const Reviews = () => {
  const { t } = useTranslation("home", { keyPrefix: "reviews" });
  return (
    <section>
      <Container fluid className="section-content">
        <h2 className="mainTitle fw-bold text-center text-primary">
          {t("title")}
        </h2>
        <p className="title fw-bold text-center">
          {t("subtitle")}
        </p>

        {reviews.map((review, i) =>
          <Review review={review} ley={i} reversed={i%2} />
        )}
      </Container>
    </section>
  )
}

const Review = ({ review, reversed }) => {
  const { t } = useTranslation("home", { keyPrefix: `reviews.reviews.${review.name}` });
  return (
    <Row className="flex-content-center py-5">
      <Col xs={12} xl={6} className={`mb-4 mb-xl-0 px-0 flex-center ${reversed ? "order-xl-last me-lg-n5 pe-xl-5" : "ps-xl-5"}`}>
        <img src={review.img} className="rounded-circle" height={300} width={300}  alt=''/>
      </Col>
      <TextColumn
        title={review.name}
        subtitle={t("subtitle")}
        text={t("review", { returnObjects: true, defaultValue: [] })}
        reversed={reversed}
      />
    </Row>
  )
}

const TextColumn = ({ title, subtitle, text, reversed}) => {
  return (
    <div
      className={'col-xl-6 px-0 d-flex flex-column justify-content-center ' + (reversed ? 'pe-xl-4 text-xl-end' : 'ps-xl-4')}>
      <h2 className='bigTitle'>{title}</h2>
      <div className='subtitle'>{subtitle}</div>
      <div className='mb-2 mb-sm-4 mt-4'>
        {text.map((t, i) => <p key={i} className="m-0 me-2">{t}</p>)}
      </div>
    </div>
  )
}


export default Reviews;
