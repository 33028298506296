import React from "react";
import { Container } from "reactstrap"

import Navbar from "../components/shared/Navbar"
import Footer from "../components/shared/Footer"
import { useTranslation } from "react-i18next";


const LegalContainer = ({children}) => {
  const { t } = useTranslation("legalTexts")
  console.log(t("test"))
  return (
    <Container fluid className="metropolis bg-white text-dark p-0">
      <Navbar/>
      <Container style={{maxWidth: 1300, minHeight: 'calc(100vh - 60px - 54px)', marginTop: 60}} className="py-5 px-4 legalText">
        {children}
      </Container>
      <Footer />
    </Container>
  );
}

export default LegalContainer;