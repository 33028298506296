
import React, { useEffect } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
// import ReactGA from "react-ga4";

import ModalWrapper from "./wrappers/ModalWrapper";
import ModalCookies from "./components/shared/ModalCookies";
import Home from "./pages/Home"

import LegalContainer from "./pages/LegalContainer";
import config from "./components/legalTexts/config.json"


// if (process.env.NODE_ENV === "production") {
//   // ReactGA.initialize("G-GJBHEPVTFG");
// }



const legalRoutes = [
  "privacy",
  "cookies"
]

const cookiesLinks = {
  FirefoxCookiesLink: <a href="http://support.mozilla.org/es/products/firefox/privacy-and-security"
                         rel="noreferrer"
                         target="_blank">http://support.mozilla.org/es/products/firefox/privacy-and-security</a>,
  ChromeCookiesLink: <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
                        rel="noreferrer"
                        target="_blank">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a>,
  OperaCookiesLink: <a href="http://www.opera.com/help/tutorials/security/cookies/" rel="noreferrer"
                       target="_blank">http://www.opera.com/help/tutorials/security/cookies/</a>
}


const App = () => {
  const { t } = useTranslation("legalTexts");
  useScrollToTop()
  return (
    <ModalWrapper>
      <ModalCookies/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        {legalRoutes.map((route, i) =>
          <Route
            key={i}
            path={"/" + route}
            element={
              <LegalContainer>
                <Trans
                  t={t} i18nKey={route}
                  components={{
                    // eslint-disable-next-line
                    br: <br/>, h1: <h1/>, h2: <h2/>, u: <u/>, ul: <ul/>, li: <li/>,
                    ol_type_a: <ol type="a"/>,
                    table: <table/>, thead: <thead/>, tbody: <tbody/>, tr: <tr/>, th: <th/>, td: <td/>,
                    // eslint-disable-next-line
                    email: <a href={`mailto:${config.email}`}/>,
                    // eslint-disable-next-line
                    phone: <a href={`tel:${config.phone}`}/>,
                    // eslint-disable-next-line
                    hostLink: <a href={`https://${window.location.host}/`}/>,
                    TableRows: <CookiesRows />,
                    ...cookiesLinks
                  }}
                  values={{...config, host: window.location.host}}
                />
              </LegalContainer>
            }/>
        )}
        <Route path="*" element={<Navigate to="/" replace />}/>
      </Routes>
    </ModalWrapper>
  );
}

const CookiesRows = () => {
  const { t } = useTranslation("legalTexts");
  return (
    t("cookiesRows", { returnObjects: true, defaultValue: [] }).map(({separator, cookie, type, duration, description}, i) =>
      <tr key={i}>
        {separator ?
          <th colSpan="100%">{separator}</th>
          :
          <>
            <td>{cookie}</td>
            <td>{type}</td>
            <td>{duration}</td>
            <td>{description}</td>
          </>
        }
      </tr>
    )
  )
}

const useScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default App;
