import React from 'react';
import { motion, AnimatePresence } from "framer-motion"

const Modal = ({ closeSelf, children }) => {

  const variants = {
    hidden: { opacity: 0, y: -100, display: 'hidden'},
    visible: { opacity: 1, y: 0, display: 'block'},
    transition: { }
  }

  return (
    <AnimatePresence>
      {children && (
        <motion.div 
          onClick={closeSelf}
          style={{ 
            zIndex: 4000, 
            backgroundColor: "rgba(0,0,0,0.6)", 
            height: '200%'
          }} 
          className="fixed-top w-100 d-flex justify-content-center"
          initial="hidden" animate="visible" exit="hidden" 
          transition="transition" variants={variants}
        >
          <div className="vh-100" onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </motion.div>
     )}
  </AnimatePresence>
  );
};

export default Modal
