import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { motion, useScroll } from "framer-motion";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useTranslation } from "react-i18next";

import logoDark from "../../media/brand/logo-landscape.svg"
import logoLight from "../../media/brand/logo-landscape-light.svg"


import spain from "../../media/flags/spain.png"
import germany from "../../media/flags/germany.png"
import france from "../../media/flags/france.png"
import italy from "../../media/flags/italy.png"
import uk from "../../media/flags/united-kingdom.png"


const languages = [
  {
    code: 'es',
    flag: spain,
    name: "Español"
  },
  {
    code: 'fr',
    flag: france,
    name: "François"
  },
  {
    code: 'de',
    flag: germany,
    name: "Deutsch"
  },
  {
    code: 'en',
    flag: uk,
    name: "English"
  },
  {
    code: 'it',
    flag: italy,
    name: "Italiano"
  }
]



const Navbar = ({ isHome }) => {
  const { scrollY } = useScroll()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    return scrollY.onChange((latest) => {
      setIsScrolled(latest > 30)
    })
  }, [scrollY])

  const bgBody = getComputedStyle(document.documentElement).getPropertyValue('--bs-body-bg').trim();

  const useDark = isScrolled || !isHome
  return (
    <motion.section
      animate={{ backgroundColor: useDark ? bgBody : undefined }}
      className="w-100 position-fixed shadow-sm py-0"
      style={{ top: 0, left: 0, zIndex: 100 }}
    >
      <div className="section-content d-flex align-items-center justify-content-between">
        <NavLink to="/" className="flex-center" style={{height: "60px"}}>
          <img src={useDark ? logoDark : logoLight} style={{ height: "35px" }} alt=""/>
        </NavLink>

        <LanguageSelector />
      </div>
    </motion.section>
  )
}


const LanguageSelector = () => {
  const { i18n } = useTranslation();
  console.log(i18n.language)
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  const selectedLang = useMemo(() => languages.find(l => l.code === i18n.language) || languages[0], [i18n.language])

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="bg-transparent border-0">
        <motion.img
          whileHover={{scale: 1.1}}
          whileTap={{scale: 1}}
          src={selectedLang.flag}
          style={{height: "35px", cursor: "pointer"}}
          alt={selectedLang.name}
        />
      </DropdownToggle>
      <DropdownMenu end>
        {languages.map(({ code, flag, name }, i) =>
          <DropdownItem key={i} active={i18n.language === code} onClick={() => i18n.changeLanguage(code)}>
            <img src={flag} style={{height: "35px"}} alt={name}/> {name}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}


export default Navbar;
