import React from "react";
import { useTranslation } from "react-i18next";

// import image from "../../media/image.png"
import image from "../../media/image.jpg"
import logo from "../../media/brand/logo-light.svg"

const MainSection = () => {
  const { t } = useTranslation("home", { keyPrefix: 'main' });
  return (
    <section className="flex-center min-vh-100 text-white" style={styles.bg}>
      <div className="mt-md-5 flex-center flex-column mx-auto" style={{maxWidth: 700, transform: 'translateY(-5vh)'}}>

        <img style={{maxWidth: "90vw", width: 350}} src={logo} alt="Logo"/>

        <h1 className="mainTitle mt-2 text-center">
          {t("title")}
        </h1>

      </div>
    </section>
  )
}

const styles = {
  bg: {
    backgroundImage: `url(${image})`,
    backgroundAttachment: "scroll",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover"

  }
}

export default MainSection;
