import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";


import Contact from "./Contact";

import logo from "../../media/brand/logo-landscape-light.svg"



const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <Container fluid tag="footer" className="section bg-dark text-white py-0">

      <Row className="py-5 section-content" >

        <Col xs={{order: 2, size: 12}} md={{order: 1, size: 5}} className="ps-2">
          <NavLink to="/">
            <img src={logo} className="ms-n2 mb-4" style={{height: 50, maxWidth: '100%'}} alt="logo"/>
          </NavLink>

          <div className="mb-4">
            <b className="h5">On-Road GmbH</b><br/>
            Limmatstrasse 258<br/>
            8005, Zürich<br/>
            Switzerland<br/>
            CHE-112.791.509
            <a href="tel:+34640208483" className="text-white mb-3 d-block" rel="noopener noreferrer">
              <b>+41 763 99 66 27</b>
            </a>
          </div>

          <div className="mb-5" style={{height: 250}}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.192311914109!2d8.5259573!3d47.3886805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47900a6ae285545d%3A0xa6bcef9a1e1b5079!2sLimmatstrasse%20258%2C%208005%20Z%C3%BCrich%2C%20Suiza!5e0!3m2!1ses-419!2ses!4v1703677545753!5m2!1ses-419!2ses"
              width="100%"
              height="100%"
              style={{border: 0}}
              loading="lazy"
              title="map"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>

          <div className="d-flex flex-md-column text-uppercase gap-2">
            <NavLink to="/cookies" className="text-white">{t("Cookies")}</NavLink>
            <NavLink to="/privacy" className="text-white">{t("Privacy policy")}</NavLink>
          </div>
        </Col>

        <Col xs={{order: 1, size: 12}} md={{order: 1, size: 7}} className="ps-md-5 mt-5 mb-7 m-md-0">
          <Contact/>
        </Col>
      </Row>

      <div className="border-top border-white text-center py-2 px-4">
        ©{(new Date()).getFullYear()} On-road Apartments
      </div>

    </Container>
  );
}

export default Footer;
