import React from 'react';
import { useTranslation } from "react-i18next";
import img from "../../media/owner.jpg"

const Owner = () => {
  const { t } = useTranslation("home", { keyPrefix: 'owner' });
  return (
    <section className="text-center">
      <h2 className="mainTitle fw-bolder">
        Georgi Stoyanov Andonov
      </h2>
      <img src={img} className="w-100 my-4 rounded-circle border border-3 border-dark" alt="" style={{maxWidth: 500}}/>
      <p className="title mx-auto" style={{maxWidth: 800}}>
        {t("text1")}
      </p>
    </section>
  )
}

export default Owner;
