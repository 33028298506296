import home from "./home.json";
import footer from "./footer.json";
import legalTexts from "./legalTexts";

const resources = {
  home,
  footer,
  legalTexts
};

export default resources;