import React from 'react';
// import { Collapse, Container, Row, Col } from 'reactstrap';
import { motion } from 'framer-motion'

// import img1 from "../../media/Grafico_depositos-768x288.jpg"
// import img2 from "../../media/rendimiento-2021.jpg"

// import plusIcon from '../../media/plus.svg'
// import minusIcon from '../../media/minus.svg'
import { useTranslation } from "react-i18next";


const FAQs = () => {
  const { t } = useTranslation("home", { keyPrefix: "faqs" });
  // const [openFAQ, setOpenFAQ] = useState();

  const faqs = t("questions", { returnObjects: true, defaultValue: [] });
  console.log('faqs', faqs)
  return (
    <section className='bg-dark text-white'>
      <div className="section-content mb-5">
        <h2 className="mainTitle fw-bold">
          {t("title")}
        </h2>
        {/*<p className="title fw-bold">Lorem ipsum dolor sit amet</p>*/}
        <div>
          {faqs.map((q, i) =>
            <CustomCollapse
              key={i} {...q}
              // isOpen={i === openFAQ}
              // toggle={() => i === openFAQ ? setOpenFAQ(null) : setOpenFAQ(i)}
            />
          )}
        </div>
      </div>
    </section>
  )
}


// const CustomCollapse = ({ toggle, isOpen, question, answer }) => (
//   <motion.div
//     onClick={toggle}
//     transition={{ duration: 0.3 }}
//     style={{ cursor: 'pointer' }}
//     className="w-100 m-0 p-0 border-white border-bottom bigText py-3"
//   >
//     <div className={`fw-bold d-flex justify-content-between align-items-center py-2 ${isOpen ? "text-primary" : ""}`}>
//       <div>{question}</div>
//       <img src={isOpen ? minusIcon : plusIcon} width={25} className="mx-4" alt=""/>
//     </div>
//     <Collapse isOpen={isOpen} className="pe-5" style={{ fontWeight: "400" }}>
//       <div className="w-100 mt-3" />
//       {answer}
//     </Collapse>
//   </motion.div>
// )

const CustomCollapse = ({ question, answer }) => {
  return (
    <motion.div className="w-100 m-0 p-0 border-white border-bottom bigText py-4">
      <div className="title fw-bold d-flex justify-content-between align-items-center py-2">
        {question}
      </div>
      <div className="pe-5" style={{ fontWeight: "400", fontSize: '1.4rem' }}>
        <div className="w-100 mt-3" />
        {answer.map((a, i) => <p key={i}>{a}</p>)}
      </div>
    </motion.div>
  )
}


export default FAQs;
