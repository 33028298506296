import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../wrappers/ModalWrapper";
import { NavLink, useLocation } from "react-router-dom";
import { Row } from 'reactstrap';

const NAME_VAR_STORAGE = "taichi-fighttime-landing-cookies-accept";

const ModalCookies = () => {
  const modal = useContext(ModalContext);
  const { pathname } = useLocation();
  const [accepted, setAccepted] = useState(!!localStorage.getItem(NAME_VAR_STORAGE));
  const [denied, setDenied] = useState(false);

  useEffect(() => {

    const accept = () => {
      localStorage.setItem(NAME_VAR_STORAGE, "true");
      setAccepted(true);
      modal.setContent(null);
    };

    const deny = () => {
      localStorage.removeItem(NAME_VAR_STORAGE);
      setAccepted(false);
      setDenied(true)
      modal.setContent(null);
    };

    if (!accepted && !denied && pathname !== "/terms-and-conditions") {
      modal.setContent(<ModalCookiesContent {...{accept, deny}}/>);
    } else {
      modal.setContent(null)
    }
    // eslint-disable-next-line
  }, [pathname, denied]);
  return null
}

const ModalCookiesContent = ({ accept, deny }) => {
  return (
    <div className="container rounded shadow bg-white text-dark pt-2" style={{width: '98%', maxWidth: 900, marginTop: '3vh'}}>
      <Row className="py-4 px-4 px-md-5" style={{ fontSize: "20px" }}>
        AVISO DE COOKIES
      </Row>
      <Row className="px-4 px-md-5">
        <p>
          El sitio web {window.location.origin} utiliza cookies propias y de terceros para recopilar información que ayuda a optimizar su visita a su página web. No se utilizarán las cookies para recoger información de carácter personal. Usted puede permitir su uso o rechazarlo, también puede cambiar su configuración siempre que lo desee.
        </p>
        <p>
          Encontrará más información en nuestra <NavLink to="/terms-and-conditions" className="text-primary" style={{ textDecoration: "underline" }}>Política de Cookies</NavLink>.
        </p>
      </Row>
      <Row className="justify-content-end pb-4 px-4 px-md-5">
        <button
          onClick={deny}
          style={{ maxWidth: "200px" }}
          className="btn btn-block py-3"
        >Denegar</button>
        <button 
          onClick={accept}
          style={{ maxWidth: "200px" }}
          className="btn text-white btn-block bg-primary rounded py-3"
        >Aceptar</button>
      </Row>
    </div>
  )
};

export default ModalCookies;
