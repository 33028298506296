import React from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";

// import photo1 from "../../media/sampleHouse/(1).jpg";
// import photo2 from "../../media/sampleHouse/(2).jpg";
// import photo3 from "../../media/sampleHouse/(3).jpg";
// import photo4 from "../../media/sampleHouse/(4).jpg";
// import photo5 from "../../media/sampleHouse/(5).jpg";
// import photo6 from "../../media/sampleHouse/(6).jpg";
// import photo7 from "../../media/sampleHouse/(7).jpg";
// import photo8 from "../../media/sampleHouse/(8).jpg";
// import photo9 from "../../media/sampleHouse/(9).jpg";
// import photo10 from "../../media/sampleHouse/(10).jpg";
// import photo11 from "../../media/sampleHouse/(11).jpg";
// import photo12 from "../../media/sampleHouse/(12).jpg";
// import photo13 from "../../media/sampleHouse/(13).jpg";
// import photo14 from "../../media/sampleHouse/(14).jpg";
// import photo15 from "../../media/sampleHouse/(15).jpg";
// import photo16 from "../../media/sampleHouse/(16).jpg";
// import photo17 from "../../media/sampleHouse/(17).jpg";
// import photo18 from "../../media/sampleHouse/(18).jpg";
// import photo19 from "../../media/sampleHouse/(19).jpg";
// import photo20 from "../../media/sampleHouse/(20).jpg";
// import photo21 from "../../media/sampleHouse/(21).jpg";
// import photo22 from "../../media/sampleHouse/(22).jpg";
// import photo23 from "../../media/sampleHouse/(23).jpg";
// import photo24 from "../../media/sampleHouse/(24).jpg";
// import photo25 from "../../media/sampleHouse/(25).jpg";
// import photo26 from "../../media/sampleHouse/(26).jpg";
// import photo27 from "../../media/sampleHouse/(27).jpg";
// import photo28 from "../../media/sampleHouse/(28).jpg";
// import photo29 from "../../media/sampleHouse/(29).jpg";
// import photo30 from "../../media/sampleHouse/(30).jpg";
// import photo31 from "../../media/sampleHouse/(31).jpg";
// import photo32 from "../../media/sampleHouse/(32).jpg";
// import photo33 from "../../media/sampleHouse/(33).jpg";
// import photo34 from "../../media/sampleHouse/(34).jpg";
// import photo35 from "../../media/sampleHouse/(35).jpg";
// import photo36 from "../../media/sampleHouse/(36).jpg";
// import photo37 from "../../media/sampleHouse/(37).jpg";
// import photo38 from "../../media/sampleHouse/(38).jpg";
// import photo39 from "../../media/sampleHouse/(39).jpg";
// import photo40 from "../../media/sampleHouse/(40).jpg";
// import photo41 from "../../media/sampleHouse/(41).jpg";
// import photo42 from "../../media/sampleHouse/(42).jpg";
// import photo43 from "../../media/sampleHouse/(43).jpg";
// import photo44 from "../../media/sampleHouse/(44).jpg";
// import photo45 from "../../media/sampleHouse/(45).jpg";
// import photo46 from "../../media/sampleHouse/(46).jpg";
// import photo47 from "../../media/sampleHouse/(47).jpg";
// import photo48 from "../../media/sampleHouse/(48).jpg";
// import photo49 from "../../media/sampleHouse/(49).jpg";
// import photo50 from "../../media/sampleHouse/(50).jpg";
// import photo51 from "../../media/sampleHouse/(51).jpg";
// import photo52 from "../../media/sampleHouse/(52).jpg";
import photo53 from "../../media/sampleHouse/(53).jpg";
// import photo54 from "../../media/sampleHouse/(54).jpg";
// import photo55 from "../../media/sampleHouse/(55).jpg";
// import photo56 from "../../media/sampleHouse/(56).jpg";
// import photo57 from "../../media/sampleHouse/(57).jpg";
// import photo58 from "../../media/sampleHouse/(58).jpg";
// import photo59 from "../../media/sampleHouse/(59).jpg";
// import photo60 from "../../media/sampleHouse/(60).jpg";
// import photo61 from "../../media/sampleHouse/(61).jpg";
// import photo62 from "../../media/sampleHouse/(62).jpg";
// import photo63 from "../../media/sampleHouse/(63).jpg";
// import photo64 from "../../media/sampleHouse/(64).jpg";

import photo1 from "../../media/sampleHouse/thumbs/(1).jpg";
import photo2 from "../../media/sampleHouse/thumbs/(2).jpg";
import photo3 from "../../media/sampleHouse/thumbs/(3).jpg";
import photo4 from "../../media/sampleHouse/thumbs/(4).jpg";
import photo5 from "../../media/sampleHouse/thumbs/(5).jpg";
import photo6 from "../../media/sampleHouse/thumbs/(6).jpg";
import photo7 from "../../media/sampleHouse/thumbs/(7).jpg";
import photo8 from "../../media/sampleHouse/thumbs/(8).jpg";
import photo9 from "../../media/sampleHouse/thumbs/(9).jpg";
import photo10 from "../../media/sampleHouse/thumbs/(10).jpg";
import photo11 from "../../media/sampleHouse/thumbs/(11).jpg";
import photo12 from "../../media/sampleHouse/thumbs/(12).jpg";
import photo13 from "../../media/sampleHouse/thumbs/(13).jpg";
import photo14 from "../../media/sampleHouse/thumbs/(14).jpg";
import photo15 from "../../media/sampleHouse/thumbs/(15).jpg";
import photo16 from "../../media/sampleHouse/thumbs/(16).jpg";
import photo17 from "../../media/sampleHouse/thumbs/(17).jpg";
import photo18 from "../../media/sampleHouse/thumbs/(18).jpg";
import photo19 from "../../media/sampleHouse/thumbs/(19).jpg";
import photo20 from "../../media/sampleHouse/thumbs/(20).jpg";
import photo21 from "../../media/sampleHouse/thumbs/(21).jpg";
import photo22 from "../../media/sampleHouse/thumbs/(22).jpg";
import photo23 from "../../media/sampleHouse/thumbs/(23).jpg";
import photo24 from "../../media/sampleHouse/thumbs/(24).jpg";
import photo25 from "../../media/sampleHouse/thumbs/(25).jpg";
import photo26 from "../../media/sampleHouse/thumbs/(26).jpg";
import photo27 from "../../media/sampleHouse/thumbs/(27).jpg";
import photo28 from "../../media/sampleHouse/thumbs/(28).jpg";
import photo29 from "../../media/sampleHouse/thumbs/(29).jpg";
import photo30 from "../../media/sampleHouse/thumbs/(30).jpg";
import photo31 from "../../media/sampleHouse/thumbs/(31).jpg";
import photo32 from "../../media/sampleHouse/thumbs/(32).jpg";
import photo33 from "../../media/sampleHouse/thumbs/(33).jpg";
import photo34 from "../../media/sampleHouse/thumbs/(34).jpg";
import photo35 from "../../media/sampleHouse/thumbs/(35).jpg";
import photo36 from "../../media/sampleHouse/thumbs/(36).jpg";
import photo37 from "../../media/sampleHouse/thumbs/(37).jpg";
import photo38 from "../../media/sampleHouse/thumbs/(38).jpg";
import photo39 from "../../media/sampleHouse/thumbs/(39).jpg";
import photo40 from "../../media/sampleHouse/thumbs/(40).jpg";
import photo41 from "../../media/sampleHouse/thumbs/(41).jpg";
import photo42 from "../../media/sampleHouse/thumbs/(42).jpg";
import photo43 from "../../media/sampleHouse/thumbs/(43).jpg";
import photo44 from "../../media/sampleHouse/thumbs/(44).jpg";
import photo45 from "../../media/sampleHouse/thumbs/(45).jpg";
import photo46 from "../../media/sampleHouse/thumbs/(46).jpg";
import photo47 from "../../media/sampleHouse/thumbs/(47).jpg";
import photo48 from "../../media/sampleHouse/thumbs/(48).jpg";
import photo49 from "../../media/sampleHouse/thumbs/(49).jpg";
import photo50 from "../../media/sampleHouse/thumbs/(50).jpg";
import photo51 from "../../media/sampleHouse/thumbs/(51).jpg";
import photo52 from "../../media/sampleHouse/thumbs/(52).jpg";
// import photo53 from "../../media/sampleHouse/thumbs/(53).jpg";
import photo54 from "../../media/sampleHouse/thumbs/(54).jpg";
import photo55 from "../../media/sampleHouse/thumbs/(55).jpg";
import photo56 from "../../media/sampleHouse/thumbs/(56).jpg";
import photo57 from "../../media/sampleHouse/thumbs/(57).jpg";
import photo58 from "../../media/sampleHouse/thumbs/(58).jpg";
import photo59 from "../../media/sampleHouse/thumbs/(59).jpg";
import photo60 from "../../media/sampleHouse/thumbs/(60).jpg";
import photo61 from "../../media/sampleHouse/thumbs/(61).jpg";
import photo62 from "../../media/sampleHouse/thumbs/(62).jpg";
import photo63 from "../../media/sampleHouse/thumbs/(63).jpg";
import photo64 from "../../media/sampleHouse/thumbs/(64).jpg";


const gallery1 = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
  photo20,
  photo21,
  photo22,
  photo23,
  photo24,
  photo25,
  photo26,
  photo27,
  photo28,
  photo29,
  photo30,
  photo31,
  photo32,
]

const gallery2 = [
  photo33,
  photo34,
  photo35,
  photo36,
  photo37,
  photo38,
  photo39,
  photo40,
  photo41,
  photo42,
  photo43,
  photo44,
  photo45,
  photo46,
  photo47,
  photo48,
  photo49,
  photo50,
  photo51,
  photo52,
  // photo53,
  photo54,
  photo55,
  photo56,
  photo57,
  photo58,
  photo59,
  photo60,
  photo61,
  photo62,
  photo63,
  photo64
]

const Photos = () => {
  const { t } = useTranslation("home", { keyPrefix: "photos" });
  return (
    <Container fluid className="p-0 bg-dark text-white" >
      <h2 className="mainTitle mb-4 fw-bold d-xl-none text-center">
        {t("title")}
      </h2>

      <Row className="m-0">

        <Col xs={12} className="d-xl-none p-0">
          <HorizontalGallery images={gallery1} height={200}/>
        </Col>

        <Col xl={3} xxl={null} className="d-none d-xl-block bg-white position-relative">
          <Gallery images={gallery1}/>
          <TopGradient />
        </Col>

        <Col xs={12} xl={6} xxl={null} className="bg-dark p-4 text-center">
          <h2 className="title fw-bold d-none d-xl-block text-center">
            {t("title")}
          </h2>

          <img src={photo53} className="w-auto my-3 mw-100" style={{ maxHeight: '80vh'}} alt=""/>

          <a href="https://abnb.me/BmABdvURRFb" target="_blank" rel="noreferrer">
            <Button color="primary" size="lg" className="border-0 rounded-0 p-3 w-50 fs-4">
              <Trans t={t} i18nKey="button" components={[<b/>]}/>
            </Button>
          </a>
        </Col>

        <Col xl={3} xxl={null} className="d-none d-xl-block bg-white position-relative">
          <Gallery images={gallery2}/>
          <TopGradient/>
        </Col>

        <Col xs={12} className="d-xl-none p-0 mb-4">
          <HorizontalGallery images={gallery2} height={200}/>
        </Col>

      </Row>

    </Container>
  )
}


const Gallery = ({images}) =>
  <div className="w-100 position-relative overflow-hidden h-100">
    <div className="position-absolute" style={{top: -50}}>

      <motion.div
        className="h-auto"
        animate={{transform: `translateY(calc(-100% + 100cqh + 50px))`}}
        transition={{duration: 180, repeat: Infinity, repeatType: "mirror", ease: "linear"}}
      >
        {images.map((src, i) =>
          <img src={src} key={i} className="w-100 my-1" alt=""/>
        )}
      </motion.div>
    </div>
  </div>

const HorizontalGallery = ({ images, height= 200 }) => {
  return (
    <div className="w-100 position-relative overflow-hidden" style={{height}}>
      <div className="position-absolute" style={{left: -50}}>
        <motion.div
          className="d-flex flex-nowrap gap-3"
          animate={{transform: `translateX(calc(-100% + 100cqw + 50px))`}}
          transition={{duration: 180, repeat: Infinity, repeatType: "mirror", ease: "linear"}}
        >
          {images.map((src, i) =>
            <img src={src} key={i} height={height} alt=""/>
          )}
        </motion.div>
      </div>
    </div>
  )
}

const TopGradient = ({ height = 100 }) =>
  <div
    className="position-absolute top-0 start-0 w-100"
    style={{ height, background: "linear-gradient(0deg, rgba(35,56,77,0) 0, rgba(35,56,77,1) 100%)" }}
  />


export default Photos;
